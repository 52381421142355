import { GlobalAppConfigInput } from "../../../lib/global"

export default defineAppConfig<GlobalAppConfigInput>({
  app: {
    domain: "finqai.com",
  },
  payments: {
    service: "stripe",
  },
  features: {
    heroCtaPriorities: [
      {
        productId: "PRODUCT_STOCK",
        planCode: "DIY",
        route: "stocks-depot",
        hash: "#subscribe-section",
      },
    ],
    shouldNavigateToSubscribeAfterLogin: false,
    displayOurProducts: true,
    showSubscriptionProfileSection: true,
    profileSubscriptionSectionRoute: "/stocks/depot",
  },
} satisfies GlobalAppConfigInput)
