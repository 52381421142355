import type { PlanCode, PricingProductEnum } from "./../../../../packages/pricing/types/index"
import type { RoutesNamesList, TypedRouteProps } from "./routes-utils"

export interface LandingCtaProperties {
  label?: string
  action?: { to?: TypedRouteProps; onClick?: () => void }
  learnMore?: { to?: TypedRouteProps; onClick?: () => void }
  hideCta?: boolean
}

export function useHomepageCta() {
  const isIsrael = isFINQIsrael()
  const i18nPrefix = computed(() => {
    return isIsrael ? "il" : "us"
  })

  const login = useLogin()
  const user = useUser()
  const { t } = useI18n({ useScope: "global" })

  const cfg = useAppConfig()

  const canAccessToProduct = computed(() => {
    if (!productToAccess.value) return false

    const { productId, planCode } = productToAccess.value
    return user.canAccessProduct(productId!, planCode ? [planCode!] : undefined)
  })

  const productToAccess = computed(() => {
    if (cfg.features?.heroCtaPriorities) {
      for (const properties of cfg.features?.heroCtaPriorities) {
        return properties as {
          productId: PricingProductEnum
          planCode: PlanCode
          route: RoutesNamesList
          hash?: string
        }
      }
    }

    return undefined
  })

  const heroCtaAction = computed(() => {
    if (isIsrael) {
      if (!user.isAuthenticated.value) {
        return {
          onClick: () => login.open({ success: () => null }, "signup"),
        }
      }

      if (!canAccessToProduct.value) return { to: { name: "pricing" } }
    }

    const { route, hash } = productToAccess.value!

    return {
      to: jsonClean({ name: route, hash: canAccessToProduct.value ? undefined : hash }),
    }
  })

  const { isAuthenticated } = useUser()

  const ctaLabel = computed(() => {
    const { productId, planCode } = productToAccess.value!
    if (canAccessToProduct.value) {
      return t(`common.cta_label.subscribed.${i18nPrefix.value}.${productId}_${planCode}`)
    } else {
      if (isAuthenticated.value) {
        return t(`common.cta_label.no_subscription.${i18nPrefix.value}`)
      }

      return t(`common.cta_label.guest.${i18nPrefix.value}`)
    }
  })

  const learnMoreCta = computed(() => {
    // if user is authenticated and can access to product, we don't show the learn more button
    if (isIsrael && isAuthenticated.value) return undefined
    if (canAccessToProduct.value) return undefined

    return {
      to: { name: isIsrael ? "pricing" : productToAccess.value?.route! },
    }
  })

  const cta = reactiveComputed(() => {
    return {
      label: ctaLabel.value,
      action: heroCtaAction.value,
      learnMore: learnMoreCta.value,
      hideCta: isIsrael && canAccessToProduct.value,
    }
  })

  return cta
}
