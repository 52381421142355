import ky from "ky"
import type { Input as KyInput, KyInstance, Options as _KyOptions } from "ky"
import type { ArraySchema, InferOutput, ObjectSchema } from "valibot"
import { ValiError, flatten as valibotFlatten, parse as valibotParse } from "valibot"

import { type ParsedQuery, stringifyQuery } from "@finq/app-base/lib/queryParams"

export type KyOptions<KyParams = ParsedQuery> = Omit<_KyOptions, "searchParams"> & {
  params?: KyParams
  responseType?: "blob" | "json" | "none"
}

function getBaseUrl() {
  const config = useConfig()
  const BASE_API_URL = config.NUXT_PUBLIC_CORE_SERVER_ORIGIN + "v1"

  return BASE_API_URL
}

function getBaseKyInstance(): KyInstance {
  const baseUrl = getBaseUrl()

  const instance = ky.create({
    prefixUrl: baseUrl,
    retry: {
      limit: 0,
    },
  })

  return instance
}

function getKyInstance(): KyInstance {
  const isRefreshing: Ref<boolean> = ref(false)
  const baseInstance = getBaseKyInstance()

  const instance = baseInstance.extend({
    credentials: "include",

    hooks: {
      beforeRequest: [
        (request) => {
          if (isServer) return

          const pageViewId = usePageViewId()
          if (!request.headers?.get("X-Page-View-Id") && pageViewId.value) {
            request.headers.set("X-Page-View-Id", pageViewId.value)
          }

          const { $i18n } = useNuxtApp()

          if (!request.headers?.get("Accept-Language") && $i18n.locale.value) {
            request.headers.set("Accept-Language", $i18n.locale.value)
          }

          const { userExists, user } = useUser()

          if (!userExists.value) return

          if (!request.headers?.get("Authorization") && user.value?.accessToken) {
            request.headers.set("Authorization", `Bearer ${user.value.accessToken}`)
          }
        },
      ],

      afterResponse: [
        // retry with a fresh token on a 403 error
        async (request, options, response) => {
          const { userExists, user, isAuthenticated } = useUser()

          if (!userExists.value) return

          if (
            /* Quit if user is not logged in */
            !isAuthenticated.value ||
            /* Quit if error is not equal to 401 */
            response?.status !== 401 ||
            /* Quit if current signal is aborted */
            request.signal.aborted ||
            /* Quit if currently refreshing */
            isRefreshing.value
          ) {
            return
          }

          try {
            const { refreshToken } = user.value!

            if (!refreshToken) return

            // Get a fresh token
            isRefreshing.value = true
            const { accessToken } = await postRefreshToken(refreshToken)

            // Retry with the token
            request.headers.set("Authorization", `Bearer ${accessToken}`)

            return ky(request)
          } catch (err) {
            console.error("error while refreshing token", err)
            await logoutUser()
          } finally {
            isRefreshing.value = false
          }
        },
      ],
    },
  })

  return instance
}

export function afterLogoutActions() {
  const { deleteUser } = useUser()

  deleteUser()

  // Reset the following stores:
  // [
  //   "favorites/reset",
  //   "stocks/reset",
  //   "funds/reset",
  //   "portfolios/reset",
  //   "cart/reset",
  //   "my_inv/reset",
  // ].map(store.dispatch);
}

async function logoutUser(): Promise<void> {
  const { $i18n } = useNuxtApp()
  const popup = usePopup()

  popup.open({ type: "warning", title: $i18n.t("system.logout_due_inactivity") })
  afterLogoutActions()
}

function mergeUser(user: User): User {
  if (isServer) return user

  const { mergeUser: _mergeUser } = useUser()

  user.loginTimestamp = Date.now()
  _mergeUser(user)

  return user
}

/**
 * Sends a POST request to refresh the user's access token using their refresh token.
 * @param refreshToken - The user's refresh token.
 * @returns A Promise that resolves with an object containing the new access token, refresh token, and expiration time, or void if the request fails.
 */
async function postRefreshToken(
  refreshToken: string
): Promise<Pick<User, "accessToken" | "refreshToken" | "expiresIn">> {
  try {
    const res = await ApiServiceSingleton.baseInstance().post("/auth/refreshtoken", { refreshToken })
    const data = res.data.data

    mergeUser(data as User)

    return data
  } catch (err) {
    return logoutUser() as any
  }
}

class ApiServiceSingleton {
  protected static _baseInstance: KyAxiosWrapper | null = null
  protected static _instance: KyAxiosWrapper | null = null

  public static baseInstance() {
    if (!this._baseInstance) {
      this._baseInstance = new KyAxiosWrapper(getBaseKyInstance())
    }

    return this._baseInstance
  }

  public static instance() {
    if (!this._instance) {
      this._instance = new KyAxiosWrapper(getKyInstance())
    }

    return this._instance
  }
}

class KyAxiosWrapper {
  constructor(private instance: KyInstance) {}

  protected async method<ResponseType extends any>(
    method: "post" | "get" | "put" | "delete" | "patch",
    url: KyInput,
    { responseType = "json", ...config }: KyOptions = {}
  ) {
    url = typeof url === "string" && url.startsWith("/") ? url.substring(1) : url
    const promise = this.instance(url, { ...config, method, searchParams: stringifyQuery(config.params) })

    const { json, blob, ...response } = await promise

    if (responseType === "json") {
      return { ...response, data: await (await promise).json<any>() }
    } else if (responseType === "blob") {
      return { ...response, data: await (await promise).blob() }
    }

    return { ...response, data: await (await promise).json<any>() }
  }

  public get(url: KyInput, config: KyOptions = {}) {
    return this.method("get", url, config)
  }

  public async post(url: KyInput, data: any, config: KyOptions = {}) {
    return this.method("post", url, { json: data, ...config })
  }

  public async put(url: KyInput, data: any, config: KyOptions = {}) {
    return this.method("put", url, { json: data, ...config })
  }

  public async patch(url: KyInput, data: any, config: KyOptions = {}) {
    return this.method("patch", url, { json: data, ...config })
  }

  public async delete(url: KyInput, config: KyOptions = {}) {
    return this.method("delete", url, config)
  }
}

export class BaseApiService {
  protected instance: KyAxiosWrapper = {} as KyAxiosWrapper

  constructor() {
    // nextTick is used to prevent SSR errors. but it still works in SSR
    nextTick(() => {
      this.instance = ApiServiceSingleton.instance()
    })
  }

  protected getData(res: any) {
    return res.data.data
  }

  protected parse<S extends ObjectSchema<any, any> | ArraySchema<ObjectSchema<any, any>, any>>(
    res: any,
    schema: S,
    errorMessage: string = "Invalid api response"
  ): InferOutput<S> {
    try {
      return valibotParse(schema, res)
    } catch (err) {
      if (err instanceof ValiError) {
        console.error(errorMessage, valibotFlatten<S>(err.issues))
      }
      throw err
    }
  }

  protected parseParams<S extends ObjectSchema<any, any> | ArraySchema<ObjectSchema<any, any>, any>>(
    res: any,
    schema: S
  ): InferOutput<S> {
    return this.parse(res, schema, "Invalid api params")
  }

  protected parsePaginated<S extends ObjectSchema<any, any> | ArraySchema<ObjectSchema<any, any>, any>>(
    res: any,
    schema: S
  ): PaginationResponse<InferOutput<S>> {
    try {
      return {
        data: valibotParse(schema, res.data) as S[],
        paging: res.paging,
        groups: res.groups,
        additional: res.additional,
      }
    } catch (err) {
      if (err instanceof ValiError) {
        console.error("Invalid api response", valibotFlatten<S>(err.issues))
      }
      throw err
    }
  }
}
