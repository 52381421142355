<template>
  <header
    class="mask-to-transparent-navbar hero relative -mt-14 grid place-content-center overflow-hidden"
    ref="heroRef"
    aria-labelledby="home-main-info-heading"
  >
    <div
      class="pointer-events-none absolute end-1/2 top-1/2 z-0 grid w-full -translate-x-1/2 -translate-y-1/2 place-content-center ltr:translate-x-1/2"
    >
      <div
        class="inner-ring transition-width absolute start-1/2 top-1/2 aspect-square -translate-y-1/2 translate-x-1/2 rounded-full border duration-500 ltr:-translate-x-1/2"
      />

      <div
        class="outer-ring transition-width absolute start-1/2 top-1/2 aspect-square -translate-y-1/2 translate-x-1/2 rounded-full border duration-500 ltr:-translate-x-1/2"
        ref="outerRingRef"
      >
        <!-- Stocks on path -->
        <div
          v-for="(image, index) in images"
          :key="image + index"
          :class="
            cn(
              'stock-on-path absolute end-0 aspect-square overflow-hidden rounded opacity-0 transition-opacity duration-700 rtl:end-[unset] rtl:start-0',
              { 'opacity-100': isMounted }
            )
          "
          :style="{
            boxShadow: '0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 2px 12px 0px rgba(0, 0, 0, 0.08)',
            animationDelay: `${index * (400 / images.length) * -1}s`,
          }"
        >
          <NuxtImg
            class="size-7 max-w-[unset] md:size-6"
            :src="getImageUrl(`homepage/asset-icon/${image}.png`)"
            :alt="image"
            loading="lazy"
          />
        </div>
      </div>
    </div>

    <div class="z-1 container flex flex-col items-center gap-10 text-center">
      <div class="flex flex-col items-center gap-4 text-lg">
        <h1
          class="md:text-4.5xl max-w-2xl text-7xl font-extrabold transition-all duration-1000 md:max-w-sm"
          id="home-main-info-heading"
          v-html-format="t(`${i18nPrefix}.hero`)"
        ></h1>

        <p class="max-w-sm text-lg/6 font-medium ltr:max-w-md">
          {{ t(`${i18nPrefix}.description`) }}
        </p>
      </div>

      <div
        class="cta-container flex items-center gap-2"
        v-auto-animate="autoAnimateFade"
        :key="cta.label ? cta.label : 'cta'"
      >
        <ClientOnly>
          <UiButton
            v-bind="cta.action"
            v-if="Object.values(cta).length && !cta.hideCta"
            :id="genClickId('index', 'hero', 'cta')"
          >
            {{ cta.label }}
          </UiButton>
          <UiButton class="font-normal" v-if="cta.learnMore" v-bind="cta.learnMore" variant="outline">
            {{ t(`${i18nPrefix}.all_plans`) }}
          </UiButton>
        </ClientOnly>
      </div>
    </div>
  </header>
</template>

<script setup lang="tsx">
import { NuxtImg } from "#components"

const emit = defineEmits(["click:getStarted"])

const { isMobile } = useDisplay()
const isMounted = useMounted()
const { t } = useI18n({ useScope: "local" })
const { isAuthenticated } = useUser()

const cta = useHomepageCta()
const heroRef = ref<HTMLElement | undefined>(undefined)
const outerRingRef = ref<HTMLElement | undefined>(undefined)
const outerRingOffsetPath = ref<string | undefined>(undefined)
const isIsrael = isFINQIsrael()

const i18nPrefix = computed(() => {
  return isIsrael ? "il" : "us"
})

function calculateOffsetPath() {
  if (!outerRingRef.value) return

  const diameter = outerRingRef.value?.offsetWidth || 0
  const radius = diameter / 2
  const iconSize = isMobile.value ? 24 : 28

  outerRingOffsetPath.value = `path("M ${-radius + iconSize},0 a ${radius} ${radius} 0 1 1 -.1 0 z")`
}

onMounted(() => calculateOffsetPath())
useResizeObserver(outerRingRef, calculateOffsetPath)

const images = [
  "tesla",
  "salesforce",
  "nvidia",
  "apple",
  "paypal",
  "google",
  "vanguard",
  "microsoft",
  "mastercard",
  "fedex",
  "meta",
  "blackrock",
]
</script>

<style lang="scss" scoped>
@use "sass:math";

.hero {
  min-height: min(100vh, 800px);
  background: linear-gradient(-258deg, #f5f5f7 0%, #fff 100%);
}

@function getSizeBasedOnViewport($maximum) {
  @return calc(#{$maximum * 0.7}vh + #{$maximum * 0.5}vw);
}

.outer-ring {
  width: getSizeBasedOnViewport(85);
  @screen md {
    width: getSizeBasedOnViewport(74);
  }
}

.inner-ring {
  width: getSizeBasedOnViewport(70);
  @screen md {
    width: getSizeBasedOnViewport(58);
  }
}

@keyframes pathLoopAnimation {
  0% {
    offset-distance: 0%;
    transform: rotate(0);
  }
  100% {
    offset-distance: 100%;
    transform: rotate(-1turn);
  }
}

.stock-on-path {
  offset-path: v-bind(outerRingOffsetPath);
  animation: pathLoopAnimation 400s linear infinite;
}

.mask-to-transparent-navbar {
  mask-image: linear-gradient(to top, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0) 98%);
}

h1 {
  background: linear-gradient(
    86deg,
    #700364 0%,
    #8b0243 13.87%,
    #a21e2d 31.9%,
    #8b8106 51.84%,
    #1e5419 71.78%,
    #034c54 86.42%,
    #023765 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

:deep(.cta-container) {
  height: 34px;
}
</style>

<i18n lang="json">
{
  "he": {
    "il": {
      "hero": "AI שנבנה להצלחה הפיננסית שלך",
      "description": "הפלטפורמה החכמה שלנו מאפשרת לך לנהל את הפנסיה שלך, להשקיע בתיק דינמי, להשתמש בתיקי המודל שלנו, ולמקסם את התשואות - הכל באמצעות AI ובלי מאמץ",
      "all_plans": "לכל התוכניות"
    },
    "us": {
      "hero": "",
      "description": "",
      "all_plans": ""
    }
  },
  "en": {
    "il": {
      "hero": "AI BUILT FOR YOUR FINANCIAL SUCCESS",
      "description": "Use our AI-driven platform to build your retirement pension, manage a dynamic portfolio, follow our model stock portfolios, or optimize your investments effortlessly",
      "all_plans": "Explore all plans"
    },
    "us": {
      "hero": "AI BUILT TO\nBEAT THE MARKET",
      "description": "Use our AI-driven platform to build your own dynamic portfolio, follow our model portfolios, or optimize your investments effortlessly",
      "all_plans": "Learn more"
    }
  }
}
</i18n>
